import React, { useMemo } from 'react'

// https://stackoverflow.com/questions/69624210/how-can-i-use-usetheme-in-material-ui-5
import { ThemeProvider, useThemeUI } from 'theme-ui'
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  useTheme as materialUseTheme,
  THEME_ID
} from '@mui/material/styles'
import { zhTW } from '@mui/material/locale'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/Download'

import CardMedia from '@mui/material/CardMedia'
// https://github.com/twobin/react-lazyload
import LazyLoad from 'react-lazyload'
// https://www.letsbuildui.dev/articles/how-to-lazy-load-react-components/

// References
// https://mui.com/material-ui/react-accordion/
// https://mui.com/material-ui/react-tabs/
// https://mui.com/material-ui/customization/default-theme/
// https://react-bootstrap.github.io/docs/components/tabs/
// https://stackoverflow.com/questions/44715819/iframes-and-react-js-how-to-embed-a-youtube-video-into-my-app/54503528#54503528

function NewTab(docName: string) {
  window.open(
    `https://mopsov.twse.com.tw/server-java/FileDownLoad?step=9&filePath=/home/html/nas/STR/&functionName=t100sb02_1&fileName=${docName}`,
    '_blank',
    'noopener, noreferrer'
  )
}

// https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
function youtube_parser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : false
}

export default function EarningsCalls({
  stockId,
  stockName,
  title,
  callDate,
  callTime,
  place,
  onlineVideo,
  afterVideo,
  companyWeb,
  chineseDoc,
  englishDoc,
  loopIndex0
}) {
  // https://www.geeksforgeeks.org/react-mui-localization/
  const [locale, setLocale] = React.useState(zhTW)
  const materialTheme = materialCreateTheme(
    {
      palette: {
        mode: 'dark'
      },
      components: {}
    },
    zhTW
  )
  const themeWithLocale = React.useMemo(
    () => materialTheme,
    [materialTheme, locale]
  )

  var reg1 = /^\d/g
  var reg2 = /^\d/g
  // const showInfo = !(title == null) && title.replace(/\s/g, '').length > 0
  const showInfo = loopIndex0 == '0'
  const showLink = !(stockId == null) && stockId.replace(/\s/g, '').length > 0
  const hasTitle = !(title == null) && title.replace(/\s/g, '').length > 0
  const showOnlineVideo =
    !(onlineVideo == null) && onlineVideo.replace(/\s/g, '').length > 0
  const showOnlineVideoLink =
    showOnlineVideo && onlineVideo.replace(/\s/g, '').startsWith('http')
  const showAfterVideo =
    !(afterVideo == null) && afterVideo.replace(/\s/g, '').length > 0
  const showAfterVideoLink =
    showAfterVideo && afterVideo.replace(/\s/g, '').startsWith('http')

  if (
    !(companyWeb == null) &&
    companyWeb.replace(/\s/g, '').startsWith('www.')
  ) {
    companyWeb = `https://${companyWeb}`
  }
  const showCompanyWeb =
    !(companyWeb == null) && companyWeb.replace(/\s/g, '').length > 0
  const showCompanyWebLink =
    showCompanyWeb && companyWeb.replace(/\s/g, '').startsWith('http')
  const showDownloadChinese =
    !(chineseDoc == null) && chineseDoc.replace(/\s/g, '').length > 0
  const showDownloadChineseLink = showDownloadChinese && reg1.test(chineseDoc)
  const showDownloadEnglish =
    !(englishDoc == null) && englishDoc.replace(/\s/g, '').length > 0
  const showDownloadEnglishLink = showDownloadEnglish && reg2.test(englishDoc)

  //   const videoId = 'ah_GV1j59jY'
  const videoId = showAfterVideoLink ? youtube_parser(afterVideo) : ''
  const cardMediaUrl: string = `https://www.youtube.com/embed/${videoId}`

  //   console.log('video id=', youtube_parser(afterVideo))

  const linkTitle = `${stockId} ${stockName}`

  return (
    <LazyLoad height={200} offset={50}>
      <ThemeProvider theme={themeWithLocale}>
        <Box sx={{ width: '100%', overflow: 'auto', marginBottom: 2 }}></Box>
        <div>
          <Accordion defaultExpanded={loopIndex0 == '0'}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              {showLink && (
                <>
                  <Link href={'/stock/' + stockId + '/'} underline='always'>
                    <Typography>{linkTitle}</Typography>
                  </Link>
                  &nbsp;&nbsp;
                </>
              )}
              <Typography>{hasTitle ? title : '無'}</Typography>
            </AccordionSummary>
            {hasTitle && (
              <AccordionDetails sx={{ width: '100%', p: 0, m: 0 }}>
                <Box
                  sx={{
                    width: '100%',
                    p: 2,
                    m: 0,
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}
                >
                  <table>
                    <tr>
                      <Typography>
                        時間：{callDate} {callTime}
                      </Typography>
                    </tr>
                    <tr>
                      <Typography>地點：{place}</Typography>
                    </tr>
                  </table>
                </Box>
                <Box sx={{ width: '100%', p: 2, m: 0 }}>
                  {showOnlineVideo && (
                    <Box sx={{ width: '100%', p: 0, marginBottom: 3 }}>
                      <Typography>同步收看網址：</Typography>{' '}
                      {showOnlineVideoLink ? (
                        <Link
                          href={onlineVideo}
                          underline='always'
                          target='_blank'
                          rel='noopener, noreferrer'
                        >
                          {onlineVideo}
                        </Link>
                      ) : (
                        <Typography>{onlineVideo}</Typography>
                      )}
                    </Box>
                  )}

                  {showAfterVideo && (
                    <Box sx={{ width: '100%', p: 0, marginBottom: 3 }}>
                      <Typography>影音資訊網址：</Typography>{' '}
                      {showAfterVideoLink ? (
                        <Link
                          href={afterVideo}
                          underline='always'
                          target='_blank'
                          rel='noopener, noreferrer'
                        >
                          {afterVideo}
                        </Link>
                      ) : (
                        <Typography>{afterVideo}</Typography>
                      )}
                    </Box>
                  )}

                  {showAfterVideoLink &&
                    !(videoId == null) &&
                    videoId.length > 0 && (
                      <CardMedia
                        component='iframe'
                        src={cardMediaUrl}
                        sx={{
                          aspectRatio: '16/9',
                          marginBottom: 3
                        }}
                      />
                    )}

                  {showCompanyWeb && (
                    <Box sx={{ width: '100%', p: 0, marginBottom: 3 }}>
                      <Typography>公司法說會網站：</Typography>{' '}
                      {showCompanyWebLink ? (
                        <Link
                          href={companyWeb}
                          underline='always'
                          target='_blank'
                          rel='noopener, noreferrer'
                        >
                          {companyWeb}
                        </Link>
                      ) : (
                        <Typography>{companyWeb}</Typography>
                      )}
                    </Box>
                  )}

                  {showDownloadChinese &&
                    (showDownloadChineseLink ? (
                      <Button
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        startIcon={<CloudDownloadIcon />}
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          NewTab(chineseDoc)
                        }}
                      >
                        <Typography>中文簡報</Typography>
                      </Button>
                    ) : (
                      <Box sx={{ width: '100%', p: 0, marginBottom: 3 }}>
                        <Typography>中文簡報：</Typography>{' '}
                        <Typography>{chineseDoc}</Typography>
                      </Box>
                    ))}

                  {showDownloadEnglish &&
                    (showDownloadEnglishLink ? (
                      <Button
                        component='label'
                        role={undefined}
                        variant='contained'
                        color='secondary'
                        tabIndex={-1}
                        startIcon={<CloudDownloadIcon />}
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          NewTab(englishDoc)
                        }}
                      >
                        <Typography>英文簡報</Typography>
                      </Button>
                    ) : (
                      <Box sx={{ width: '100%', p: 0, marginBottom: 3 }}>
                        <Typography>英文簡報：</Typography>{' '}
                        <Typography>{englishDoc}</Typography>
                      </Box>
                    ))}
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        </div>
      </ThemeProvider>
    </LazyLoad>
  )
}
